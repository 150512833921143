@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

* {
  font-family: 'Lato', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}